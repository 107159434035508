<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col cols="12" sm="12" md="3" >
                <h5>Clientes</h5>
              </v-col>
              <v-col cols="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="customerSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.customer)"
                >
                  Crear Cliente
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.customer"
              :items="customer_records"
              :items-per-page="20"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :search="customerSearch"
              :loading="loadingCustomerTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.customer)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.customer)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Customer Form -->
    <v-row justify="center" >
      <v-form ref="createCustomerForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogCustomer" persistent max-width="600px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Cliente ${createCustomerForm.nickname}` : `Crear Cliente` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="6">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCustomerForm.document_id_type"
                      item-value="id"
                      dense
                      :items="document_id_types"
                      item-text="name"
                      label="Tipo de documento"
                      required
                      :rules="[createFormRules.required]"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Número de documento"
                      v-model="createCustomerForm.number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dende>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      label="Primer nombre"
                      v-model="createCustomerForm.first_name"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      label="Segundo nombre"
                      v-model="createCustomerForm.middle_name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      label="Apellidos"
                      v-model="createCustomerForm.last_name"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                     <v-text-field
                        v-model="email"
                        label="E-mail"
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Nombre de usuario"
                      v-model="createCustomerForm.nickname"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Título"
                      v-model="createCustomerForm.title"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCustomerForm.vip_status"
                      item-value="id"
                      dense
                      :items="vip_statues"
                      item-text="name"
                      label="Status VIP"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCustomerForm.repeat_guess"
                      item-value="id"
                      dense
                      :items="repeat_guess"
                      item-text="name"
                      label="Cliente frecuente"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Dirección"
                      v-model="createCustomerForm.address"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      label="Teléfono"
                      v-model="createCustomerForm.phone"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      dense
                      label="Celular"
                      v-model="createCustomerForm.celphone"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="4">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCustomerForm.nationality"
                      item-value="id"
                      dense
                      :items="nationalities"
                      item-text="name"
                      label="Nacionalidad"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCustomerForm.gender"
                      item-value="id"
                      dense
                      :items="genders"
                      item-text="name"
                      label="Género"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-menu
                      v-model="menuStarDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      required
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="createCustomerForm.birthdate"
                          label="Fecha de nacimiento"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="createCustomerForm.birthdate"
                        @input="menuStarDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="6">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createCustomerForm.business_type"
                      item-value="id"
                      dense
                      :items="business_types"
                      item-text="name"
                      label="Tipo de negocio"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Nombre del negocio"
                      v-model="createCustomerForm.business_name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.customer)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.customer)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.customer)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

   

    <!-- DIALOG DELETE CUSTOMER -->
    <v-dialog v-model="dialogDeletepCustomer" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este Cliente?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.customer)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.customer)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      email: '',
      emailId: '',
      //loadings
      isLoading: false,
      loadingCustomerTable: false,
      //dialog delete
      dialogDeletepCustomer: false,
      //search
      customerSearch: '',
      //records
      customer_records: [],
      document_id_types: [],
      vip_statues: [],
      repeat_guess: [],
      nationalities: [],
      genders: [],
      business_types: [],
      //form
      menuStarDate: false,
      btnEdithVisible: false,
      valid: false,
      dialogCustomer: false,
      createCustomerForm: {},
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.customer) {
        if (!this.$refs.createCustomerForm.validate()) {
          error = true
        } else {
          form = this.createCustomerForm
          form.email = this.email
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((res) => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((res) => {
            let obj = {
              method: 'PUT',
              url: `email/update`,
              form: { id: this.emailId, email: this.email }
            }
            this.emailSummit(obj)
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response.data.message : err}`)
            this.isLoading = false
          })
        }
      } else {
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    async emailSummit(options){
      await this.$store.dispatch('verifyToken')
      // if (options.method == 'POST') {
      //   axiosN506(options.url, {
      //     method: "POST",
      //     data: options.form,
      //     headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      //   })
      //   .then(() => {
      //     this.isLoading = false
      //   }).catch((err) => {
      //     alert(`error: ${err.response ? err.response : err}`)
      //     this.isLoading = false          
      //   })
      // } 
      if (options.method == 'PUT') {
        axiosN506(options.url, {
          method: "PUT",
          data: options.form,
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then(() => {
          this.isLoading = false
        }).catch((err) => {
          alert(`error: ${err.response ? err.response : err}`)
          this.isLoading = false          
        })
      }

    },
    editItem(item, board){
      console.log(item)
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.customer) {
        this.createCustomerForm.document_id_type = item.document_id_type
        this.createCustomerForm.number = item.number
        this.createCustomerForm.first_name = item.first_name
        this.createCustomerForm.middle_name = item.middle_name
        this.createCustomerForm.last_name = item.last_name
        this.createCustomerForm.nickname = item.nickname
        this.createCustomerForm.title = item.title
        this.createCustomerForm.vip_status = item.vip_status
        this.createCustomerForm.repeat_guess = item.repeat_guess
        this.createCustomerForm.address = item.address
        this.createCustomerForm.phone = item.phone
        this.createCustomerForm.celphone = item.celphone
        this.createCustomerForm.nationality = item.nationality
        this.createCustomerForm.gender = item.gender
        this.createCustomerForm.business_type = item.business_type
        this.createCustomerForm.business_name = item.business_name
        this.createCustomerForm.birthdate = item.birthdate
        this.dialogCustomer = true
        this.email = item.emailId ? item.emailId.email : '' 
        this.emailId = item.emailId ? item.emailId.id : '' 
      }
      
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.customer) this.dialogDeletepCustomer = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.customer) this.dialogCustomer = true
    },
    closeForm(board) {
      if (board == this.boards.customer) {
        this.dialogCustomer = false
        this.$refs.createCustomerForm.resetValidation()
        this.createCustomerForm = {}
      }
      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
      this.email = ''
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.customer) this.dialogDeletepCustomer = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list?id[order]=desc`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.customer) this.loadingCustomerTable = false
        if (board == this.boards.customer) this.customer_records = result.data.data
        if (board == 'document_id_type') this.document_id_types = result.data.data
        if (board == 'vip_status') this.vip_statues = result.data.data
        if (board == 'repeat_guess') this.repeat_guess = result.data.data
        if (board == 'nationality') this.nationalities = result.data.data
        if (board == 'gender') this.genders = result.data.data
        if (board == 'business_type') this.business_types = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.customer)
    this.getData('document_id_type')
    this.getData('vip_status')
    this.getData('repeat_guess')
    this.getData('nationality')
    this.getData('gender')
    this.getData('business_type')
  }
}
</script>