<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        CLIENTES
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <CustomersTable :headers="headers" :boards="boards">

       </CustomersTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import CustomersTable from '@/components/CustomersTable.vue' 
export default {
  name: 'Customers',
  components: {
    CustomersTable
  },
  data() {
    return {
      headers: {
        customer: [
          {
            text: 'Numero',
            align: 'start',
            value: 'id',
          },
          {
            text: 'Tipo de documento',
            align: 'start',
            value: 'document_id_type',
          },
          {
            text: 'Número documento',
            align: 'start',
            value: 'number',
          },
          {
            text: 'Primer nombre',
            align: 'start',
            value: 'first_name',
          },
          // {
          //   text: 'Segundo nombre',
          //   align: 'start',
          //   value: 'middle_name',
          // },
          {
            text: 'Apellido',
            align: 'start',
            value: 'last_name',
          },
          // {
          //   text: 'Nombre de usuario',
          //   align: 'start',
          //   value: 'nickname',
          // },
          // {
          //   text: 'Título',
          //   align: 'start',
          //   value: 'title',
          // },
          // {
          //   text: 'Estado VIP',
          //   align: 'start',
          //   value: 'vip_status',
          // },
          // {
          //   text: 'Cliente frecuente',
          //   align: 'start',
          //   value: 'repeat_guess',
          // },
          {
            text: 'Dirección',
            align: 'start',
            value: 'address',
          },
          {
            text: 'Teléfono',
            align: 'start',
            value: 'phone',
          },
          {
            text: 'Celular',
            align: 'start',
            value: 'celphone',
          },
          {
            text: 'Nacionalidad',
            align: 'start',
            value: 'nationality',
          },
          // {
          //   text: 'Género',
          //   align: 'start',
          //   value: 'gender',
          // },
          // {
          //   text: 'Tipo de negocio',
          //   align: 'start',
          //   value: 'business_type',
          // },
          // {
          //   text: 'Nombre de negocio',
          //   align: 'start',
          //   value: 'business_name',
          // },
          // {
          //   text: 'Fecha de nacimiento',
          //   align: 'start',
          //   value: 'birthdate',
          // },
          {
            text: 'Email',
            align: 'center',
            value: 'emailId.email'
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
      },
      boards: {
        customer: 'customer',
      }
    }
  },
}
</script>

<style>

</style>